<template>
  <!-- 文件预览组件 预览文件组件-->
  <div class="fileViewBox">
    <!--  使用案例
    <new-viewonline :viewSrc="item.view_url" :fileExt="item.mime || item.ext" :itemData="item" :fileTitle="item.title" :fileSize="item.size"
      :fileTime="item.create_time" fileNav="个人中心 > 我的资源 > 查看">
      <div class="btn_2 btn_3">查看</div>
    </new-viewonline>
    -->

    <div @click="handler_type()">
      <slot></slot>
    </div>

    <!-- 图片 -->
    <PublicBoxMaskDialog v-if="image_DialogShow" DialogTitle="" :DialogShow="image_DialogShow"
      @DialogCloseEvente="closeDialogEvent" DialogWidth="1200px">
      <template slot="contentMinBox">
        <el-image class="outside_div" :src="viewSrc" fit="contain"></el-image>
      </template>
    </PublicBoxMaskDialog>

    <!-- 文档 -->
    <PublicBoxMaskDialog v-if="doc_DialogShow" DialogTitle="" :DialogShow="doc_DialogShow"
      @DialogCloseEvente="closeDialogEvent" DialogWidth="1200px">
      <template slot="contentMinBox">
        <!-- <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + wordurl" width="100%" height="98%" seamless frameborder="0"></iframe> -->
        <div class="outside_div" v-loading="iframe_loading" element-loading-text="资源加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)">
          <iframe :src="viewSrc" width="100%" height="100%" seamless frameborder="0" @load="loadFrameEvent"
            @error="loadFrameEvent"></iframe>
        </div>
      </template>
    </PublicBoxMaskDialog>

    <!-- 视频 -->
    <PublicBoxMaskDialog v-if="video_DialogShow" DialogTitle="" :DialogShow="video_DialogShow"
      @DialogCloseEvente="closeDialogEvent" DialogWidth="1200px">
      <template slot="contentMinBox">
        <div class="outside_div">
          <!-- <Ckplayer :videoSrc="viewSrc" :videoLoop="true"></Ckplayer> -->
          <video :src="viewSrc" type="video/mp4" poster="false.png" autoplay="autoplay" controls="controls" loop="-1"
            style="width: 100%; height: 100%; border: 1px solid #ebeef5;">
          </video>
        </div>
      </template>
    </PublicBoxMaskDialog>

  </div>
</template>

<script>
import { localGet, localSet, localRemove } from "@/utils/utils.js";
export default {
  props: {
    // 教学室用户资源id
    teaching_studio_user_resource_id: {
      default: '',
    },
    // 资源id
    resourceId: {
      default: '',
    },
    // 工作室id
    teaching_studio_id: {
      default: '',
    },
    // 隐藏工作导航 =1
    is_hidden_nav: {
      default: '',
    },
    // 显示头部 =1
    is_show_head: {
      default: '',
    },
    // 文件 导航
    fileNav: {
      default: '',
    },
    // 文件 标题
    fileTitle: {
      default: '',
    },
    // 文件 大小
    fileSize: {
      default: '',
    },
    // 预览 时间
    fileTime: {
      default: '',
    },
    // 预览 链接
    viewSrc: {
      type: String,
      default: '',
    },
    // 文件 后缀
    fileExt: {
      type: String,
      default: '',
    },
    // item 整个信息
    itemData: {
      default: '',
    },
  },
  data() {
    return {
      unserInfoData: null,
      // 视频
      video_DialogShow: false,
      // 图片
      image_DialogShow: false,
      // 文档
      doc_DialogShow: false,
      iframe_loading: false,
    };
  },
  mounted() {
    let unserInfoData = localGet("unserInfoData");
    if (unserInfoData && unserInfoData.id && unserInfoData.token) {
      this.unserInfoData = unserInfoData;
    } else {
      this.unserInfoData = null;
    };

  },
  methods: {
    // 关闭预览弹框
    closeDialogEvent() {
      this.video_DialogShow = false;
      this.image_DialogShow = false;
      this.doc_DialogShow = false;
      this.iframe_loading = false;
    },
    // iframe加载完成
    loadFrameEvent() {
      this.iframe_loading = false;
    },
    // 打开去预览
    handler_type() {
      let ext = this.fileExt;
      if (!this.unserInfoData && !this.viewSrc) {
        this.$message({
          message: '请先登录!',
          type: 'error'
        });
        return false;
      };
      if (!this.viewSrc) {
        this.$message({
          message: 'view_url 预览链接为空，无法预览',
          type: 'error'
        });
        return false;
      };
      //完成浏览回调
      if (this.viewSrc && (this.isTypeImage(ext) || this.isTypeDoc(ext) || this.isTypeVideo(ext))) {
        this.$emit("browseFinish", this.itemData.id);
      };
      if (this.isTypeImage(ext)) { // 图片
        this.handleFileView(ext);
      } else if (this.isTypeDoc(ext)) { // 文档
        this.handleFileView(ext);
      } else if (this.isTypeVideo(ext)) { // 视频
        this.handleFileView(ext);
      } else if (this.isTypeAudio(ext)) { // 音频
        this.handleFileView(ext);
      } else {
        this.$message({
          type: 'info',
          message: `暂不支持查看该类型文件`
        })
      };
    },
    //是否 图片
    isTypeImage(ext) {
      return ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"].includes(ext.toLowerCase());
    },
    // 是否 文档
    isTypeDoc(ext) {
      return ["epub", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"].includes(ext.toLowerCase());
    },
    // 是否 视频
    isTypeVideo(ext) {
      return ["avi", "wmv", "mpeg", "mp4", "m4v", "flv", "f4v", "rmvb", "rm", "3gp", "vob"].includes(ext.toLowerCase());
    },
    // 是否 音频
    isTypeAudio(ext) {
      return ["mp3", "wav", "wma", "flac", "midi", "ra", "ape", "aac", "cda"].includes(ext.toLowerCase());
    },
    // 跳转预览页面
    handleFileView(ext) {
      // if (this.isTypeVideo(ext)) { // 视频
      //   if (this.itemData?.resource_url) {
      //     this.viewSrc = this.itemData.resource_url;
      //   } else if (this.itemData?.url) {
      //     this.viewSrc = this.itemData.url;
      //   }
      // };
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/fileViewPage?viewSrc=${encodeURIComponent(this.viewSrc)}&fileTitle=${this.fileTitle}&fileExt=${this.fileExt}&fileSize=${this.fileSize}&fileTime=${this.fileTime}&fileNav=${this.fileNav}&is_show_head=${this.is_show_head}&is_hidden_nav=${this.is_hidden_nav}&teaching_studio_id=${this.teaching_studio_id}&resourceId=${this.resourceId}&teaching_studio_user_resource_id=${this.teaching_studio_user_resource_id}`)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
      } else {
        let routeData = this.$router.resolve({
          path: "/fileViewPage",
          query: {
            viewSrc: encodeURIComponent(this.viewSrc),
            fileTitle: this.fileTitle,
            fileExt: this.fileExt,
            fileSize: this.fileSize,
            fileTime: this.fileTime,
            fileNav: this.fileNav,
            is_show_head: this.is_show_head,
            is_hidden_nav: this.is_hidden_nav,
            teaching_studio_id: this.teaching_studio_id,
            resourceId: this.resourceId,
            teaching_studio_user_resource_id: this.teaching_studio_user_resource_id,
            downUrl: this.itemData.url || this.itemData.resource_url,
          }
        });
        window.open(routeData.href, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fileViewBox {
  display: inline-block;
}

.outside_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>